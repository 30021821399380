import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TreatmentGroups from "../components/treatment-groups"
import { SideInfoBox } from "../components/styles/SideInfoBox"
import Icon from "../utilities/icons"

const TreatmentListWrapper = styled.div``

const TreatmentsPage = () => {
  return (
    <Layout>
      <SEO
        title="Beauty Treatments | Beauty Gems - Mobile Beautician Newark"
        description="View all treatments available from Beauty Gems - Mobile Beautician Newark"
      />
      <div className="standard-page-container">
        <div className="inner-container">
          <h1>Treatments</h1>
          <p>
            Here are all the treatments I offer, and their prices. Click on each
            treatment to find out a little bit more about what is involved. If
            you have any questions or would like to request a treatment, please
            contact me.
          </p>
          <div className="two-column-layout-wrapper">
            <div className="two-column-layout-item_wide ">
              <TreatmentListWrapper>
                <TreatmentGroups />
              </TreatmentListWrapper>
            </div>
            <div className="two-column-layout-item_narrow">
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Quick Contact</h3>
                <ul className="contact-box-list">
                  <li>
                    <b>Phone / Text:</b> 07796 252618
                  </li>
                  <li>
                    <b>Email: </b>
                    <a href="mailto:gemmabeautygems@gmail.com">
                      {" "}
                      gemmabeautygems@gmail.com
                    </a>
                  </li>

                  <li>
                    <b>Message Direct: </b>{" "}
                    <Link to="/contact">
                      <Icon
                        name="mail"
                        color="#ef017c"
                        width="16"
                        height="16"
                      />
                    </Link>
                  </li>
                  <li>
                    <b>Facebook: </b>
                    <a href="https://www.facebook.com/beautygems1/">
                      <Icon
                        name="facebook"
                        color="#3C5A99"
                        width="16"
                        height="16"
                      />
                    </a>
                  </li>
                </ul>
              </SideInfoBox>
            </div>
          </div>
        </div>
      </div>
    </Layout> 
  )
}

export default TreatmentsPage
